<template>
  <div>
    <b-card title="Tahsilat bilgileri">
      <validation-observer ref="simpleRules">
        <collection-form />
      </validation-observer>
    </b-card>
    <b-row>
      <b-col>
        <b-button
          :disabled="dataItem.submitStatus"
          variant="primary"
          @click="submitForm"
        >
          <feather-icon icon="SaveIcon" />
          Kaydet
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CollectionForm from '@/views/PaymentCollections/Components/CollectionForm.vue'

export default {
  name: 'Add',
  components: {
    CollectionForm,
    BCard,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['paymentCollections/dataItem']
    },
    saveData() {
      return this.$store.getters['paymentCollections/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.dataItem.submitStatus = false
        this.$router.push('/payment-collections')
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
        this.dataItem.submitStatus = false
      }
    },
  },
  created() {
    localize('tr')
    this.$store.commit('paymentCollections/RESET_DATA_ITEM')
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          this.dataItem.id_payment_collection_status = 1
          this.dataItem.id_customers = this.$route.params.id_customers
          this.$store.dispatch('paymentCollections/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>
