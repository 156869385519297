<template>
  <div>
    <b-form-group
      label="Ödeme Tarihi"
      label-for="title"
    >
      <validation-provider
        #default="{ errors }"
        name="Ödeme Tarihi"
        rules="required"
      >
        <b-form-datepicker
          id="sdate"
          v-model="dataItem.pdate"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import { ValidationProvider } from 'vee-validate'
import { BFormDatepicker, BFormGroup } from 'bootstrap-vue'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'DateCard',
  components: {
    BFormDatepicker,
    BFormGroup,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['paymentCollections/dataItem']
    },
  },
}
</script>
