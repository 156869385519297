<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <title-card />
      </b-col>
      <b-col md="6">
        <amount-card />
      </b-col>
      <b-col md="6">
        <date-card />
      </b-col>
      <b-col
        cols="12"
      >
        <b-form-checkbox
          v-model="dataItem.currentAccount"
          switch
          value="1"
        >
          Firma cari işlem listesine kaydet
        </b-form-checkbox>
        <b-alert
          v-if="dataItem.currentAccount"
          variant="info"
          show
        >
          <div class="alert-body font-small-2">
            <FeatherIcon icon="InfoIcon" />
            Firmanın cari işlem listesine kayıt oluşturulacaktır. Eğer eklenen kayıt zaten cari işlem listesinde bir hareket içeriyorsa, bu seçeneği kapatabilirsiniz.
          </div>
        </b-alert>
      </b-col>
      <b-col
        cols="12"
      >
        <b-form-checkbox
          v-model="dataItem.partial"
          switch
          value="1"
        >
          Kısmi ödemeye izin ver.
        </b-form-checkbox>
        <b-alert
          v-if="dataItem.partial === '1'"
          variant="info"
          show
        >
          <div class="alert-body font-small-2">
            <FeatherIcon icon="InfoIcon" />
            Kullanıcı girilen tutarın altında kısmi ödeme yapabilir.
          </div>
        </b-alert>
      </b-col>
      <b-col
        cols="12"
        class="mb-1"
      >
        <hr>
        <div class="font-weight-bold">
          Ödeme Hatırlatıcı
        </div>
      </b-col>
      <b-col
        cols="12"
        md="auto"
      >
        <b-form-checkbox
          v-model="dataItem.alert1"
          switch
          value="1"
        >
          1 Gün Öncesinde Hatırlat
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        md="auto"
      >
        <b-form-checkbox
          v-model="dataItem.alert3"
          switch
          value="1"
        >
          3 Gün Öncesinde Hatırlat
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        md="auto"
      >
        <b-form-checkbox
          v-model="dataItem.alert5"
          switch
          value="1"
        >
          5 Gün Öncesinde Hatırlat
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormCheckbox, BAlert,
} from 'bootstrap-vue'
import TitleCard from '@/views/PaymentCollections/Elements/Title.vue'
import AmountCard from '@/views/PaymentCollections/Elements/Amount.vue'
import DateCard from '@/views/PaymentCollections/Elements/Date.vue'

export default {
  name: 'CollectionForm',
  components: {
    DateCard,
    AmountCard,
    TitleCard,
    BFormCheckbox,
    BRow,
    BCol,
    BAlert,
  },
  computed: {
    dataItem() {
      return this.$store.getters['paymentCollections/dataItem']
    },
  },
}
</script>
